<template>
  <section class="container">
    <div>
      <h1>{{ $t("global.user.api") }}</h1>
    </div>

    <div class="row mt-2 mb-2">
      <div class="col-12">
        <hr />
      </div>
    </div>
    <template v-if="$can('update', 'Apikey') && isMe">
      <div class="row">
        <div class="col-12 mb-1">
          <i
            >API-nøglen anvendes ifm med eksport / import af data via
            rapportingsværktøjet.
            <br />
            <b>OBS:</b>
            <ol class="pl-3">
              <li>Det er vigtig at du ikke deler din API-nøgle med andre.</li>
              <li>
                Hvis du af noget grund mistænker at api-nøglen er blevet
                kompromitteret, kan du generere en ny, og den gamle nøgle vil
                blive slettet.
              </li>
            </ol>
          </i>
        </div>

        <div class="col-12">
          <template v-if="apikeyReady">
            <template v-if="apikey">
              Din nøgle: <b> {{ apikey }}</b>
            </template>
            <br />
            <BaseActionButton
              v-bind:clicked="apikeyClicked"
              v-bind:disabled="apikeyClicked"
              v-on:on-click="registerApikey"
              type="button"
              class="mt-2"
            >
              Opret en ny api nøgle
            </BaseActionButton>
          </template>
          <template v-if="!apikeyReady">
            <font-awesome-icon icon="spinner" class="fa-spin" />
          </template>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
export default {
  name: "api",
  data() {
    return {
      apikeyClicked: false,
    };
  },
  async mounted() {
    this.$parent.$emit("updateLoader");
    if (this.isMe) {
      await this.$store.dispatch("userVuex/getApikey", this.id);
    }
  },
  methods: {
    async registerApikey() {
      this.apikeyClicked = true;
      await this.$store.dispatch("userVuex/registerApikey", this.id);
      this.toast("Success", "Din api nøgle er nu opdateret", true);
      this.apikeyClicked = false;
    },
  },
  computed: {
    isMe() {
      return this.id == this.myUid;
    },
    id() {
      return this.$route.params.id;
    },
    myUid() {
      return this.$store.getters["auth/uid"];
    },
    apikey() {
      return this.$store.getters["userVuex/apikey"]?.id ?? "";
    },
    apikeyReady() {
      return this.$store.getters["userVuex/apikeyReady"];
    },
  },
};
</script>

<style>
</style>